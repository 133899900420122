<!-- eslint-disable vue/no-v-html -->
<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <!-- MOSTRAR -->
    <v-dialog
      v-model="isDialogVisible"
      scrollable
      max-width="450px"
    >
      <v-card max-height="600px">
        <v-card-title>{{ $t('lbl.afiliado') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="ejecutivo_id"
                :items="ejecutivos"
                item-text="name"
                item-value="id"
                :label="$t('lbl.ejecutivo')"
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                  <span
                    v-if="item.email"
                    class="caption"
                  > ({{ item.email }}) </span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <span
                        v-if="item.email"
                        class="caption"
                      > ({{ item.email }}) </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="is_proveedor"
              cols="12"
              md="12"
            >
              <v-select
                v-model="typeAfiliado"
                :items="typeAfiliados"
                item-text="name"
                item-value="id"
                :label="$t('menu.typeAfiliado')"
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ item }">
                  {{ item.name }}
                  <span
                    v-if="item.description"
                    class="caption"
                  > ({{ item.description }}) </span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <span
                        v-if="item.description"
                        class="caption"
                      > ({{ item.description }}) </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <!--<v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="codeUnique"
                :label="$t('lbl.code')"
                disabled
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>-->
            <v-col
              cols="12"
              md="12"
            >
              <strong>{{ $t('menu.products') }}</strong>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-checkbox
                    v-model="allItems"
                    label="Todos"
                    hide-details
                    class="pt-0 pb-0 mt-0 mb-0"
                    @click="setAllItems"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="(product, i) in products"
                  :key="i"
                  cols="12"
                  md="6"
                >
                  <v-checkbox
                    v-model="nom_tipo_products_id"
                    :label="product.name"
                    :value="product.id"
                    hide-details
                    class="pt-0 pb-0 mt-0 mb-0"
                    @click="verifyAllItems"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            outlined
            @click="isDialogVisible = !isDialogVisible"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="aprobar"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Multiple Column -->
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.configuration')} / ${$t('menu.companies')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'user-register-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>

            <v-spacer></v-spacer>

            <v-col
              v-if="!model.procesada"
              cols="12"
              md="3"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="denegar()"
              >
                <span>{{ $t('lbl.denegSolic') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="isDialogVisible = true"
              >
                <span>{{ $t('lbl.acceptSolic') }}</span>
              </v-btn>
            </v-col>
          </v-row>

          <!--SOLICITUD DENEGADA-->
          <v-row
            v-if="model.procesada && !model.aprobada && model.denegation !== ''"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-card-title>
                  {{ $t('lbl.denegarMotiveInfo') }}
                </v-card-title>
                <v-card-text>
                  <p v-html="model.denegation"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <!--DATOS GENERALES-->
            <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-card-title>
                  {{ $t('register.titlePerson') }}
                </v-card-title>
                <v-card-text>
                  <p>
                    {{ $t('register.namePerson') }}: <b>{{ model.name_contact }} {{ model.apellidos_contact }}</b><br />
                    {{ $t('register.emailPerson') }}: <b>{{ model.email_contact }}</b><br />
                    {{ $t('register.noTelefo') }}: <b>{{ model.prefijo_number_contact }} {{ model.number_contact }}</b><br />
                    {{ $t('register.nameCompany') }}: <b>{{ model.name_comercial }}</b><br />
                    <a
                      v-if="model.site_web"
                      :href="model.site_web"
                      target="_blank"
                    ><i>{{ model.site_web }}</i></a>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <!--DATOS DE SOLICITUD-->
            <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-card-title>
                  {{ $t('register.titleSolicitude') }}
                </v-card-title>
                <v-card-text>
                  <p v-if="model.data_country_contact">
                    <span v-if="model.data_country_contact.ip">
                      IP: <b>{{ model.data_country_contact.ip }}</b><br />
                    </span>
                    <span v-if="model.data_country_contact.network">
                      Network: <b>{{ model.data_country_contact.network }}</b><br />
                    </span>
                    <span v-if="model.data_country_contact.city">
                      {{ $t('lbl.city') }}: <b>{{ model.data_country_contact.city }}</b>
                    </span>
                    <span v-if="model.data_country_contact.country_name">
                      &nbsp;&nbsp;&nbsp;{{ $t('lbl.country') }}: <b>{{ model.data_country_contact.country_name }}</b><br />
                    </span>
                    <span v-if="model.data_country_contact.org">
                      Proveedor de Internet: <b>{{ model.data_country_contact.org }}</b><br />
                    </span>
                    <span v-if="model.fecha_registro">
                      {{ $t('lbl.dateRegistro') }}: <b>{{ $moment(model.fecha_registro).format('D-MMM-YY') }}</b><br />
                    </span>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <!--DATOS FACT-->
            <v-col
              cols="12"
              md="12"
            >
              <v-card>
                <v-card-title>
                  {{ $t('register.titleFact') }}
                </v-card-title>
                <v-card-text>
                  <p>
                    {{ $t('register.nameFact') }}: <b>{{ model.name_fiscal_facturation }}</b><br />
                    {{ $t('lbl.tipo') }}: <b>{{ model.tipo_fiscal }}</b><br />
                    {{ $t('register.idFiscal') }}: <b>{{ model.cif_nif_facturation }}</b><br />

                    {{ $t('register.noBanco') }}: <b>{{ model.no_cuenta_bancaria }}</b><br />
                    {{ $t('register.tipoBanco') }}: <b>{{ model.tipo_cuenta_bancaria }}</b><br />
                    {{ $t('register.moneda') }}: <b>{{ model.moneda }}</b><br />
                    {{ $t('register.nameBanco') }}: <b>{{ model.name_banco }}</b><br />
                    {{ $t('register.codeSWIFT') }}: <b>{{ model.swift }}</b><br />
                    IBAN, IRC o CLABE: <b>{{ model.iban }}</b><br />
                    {{ $t('register.codeSucursal') }}: <b>{{ model.code_sucursal }}</b><br />
                    {{ $t('register.address') }}: <b>{{ model.direction_facturation }}</b><br />
                    {{ $t('register.codPostal') }}: <b>{{ model.code_postal_facturation }}</b><br />
                    {{ $t('register.locality') }}: <b>{{ model.localidad_facturation }}</b><br />
                    {{ $t('register.country') }}: <b>{{ model.country_facturation }}</b><br />
                    <!--{{ $t('register.noTelefo') }}:
                    <b>{{ model.prefijo_number_facturation }} {{ model.number_facturation }}</b><br />
                    {{ $t('register.emailPerson') }}: <b>{{ model.email_facturation }}</b><br />-->
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <!--PERFILES-->
            <v-col
              cols="12"
              md="12"
            >
              <v-card>
                <v-card-title>
                  {{ $t('lbl.profiles') }}
                </v-card-title>
                <v-card-text>
                  <p>
                    <span
                      v-for="(perfil, i) in model.perfiles"
                      :key="i"
                      cols="12"
                      md="12"
                    >
                      <v-card>
                        <v-card-text>
                          <span
                            class="me-2"
                          ><b>{{ perfil.name }}</b></span>
                        </v-card-text>
                        <v-card-subtitle>{{ perfil.description }}</v-card-subtitle>
                      </v-card>
                    </span>
                    <br />
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!--<v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="model.nom_tipo_product_id"
                  :items="typeProducts"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.typeProduct')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="model.tipo_afiliado_id"
                  :items="typeAfiliados"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.typeAfiliado')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.valor"
                  :label="$t('lbl.fee')"
                  outlined
                  dense
                  hide-details
                  min="0"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.pc"
                  :label="$t('lbl.pc')"
                  outlined
                  dense
                  min="0"
                  type="number"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>-->
        </v-card-text>
      </app-card-code>
    </v-col>
    <SidebarDenegation
      v-if="isSidebarDenegationActive"
      v-model="isSidebarDenegationActive"
      @confirmDenegar="confirmDenegar"
    />
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify, mdiDeleteOutline, mdiSquareEditOutline, mdiPlus, mdiArrowLeft, mdiUpdate,
} from '@mdi/js'

import SidebarDenegation from './utils/SidebarDenegation.vue'

export default {
  components: {
    AppCardCode,
    SidebarDenegation,
  },
  data() {
    return {
      isSidebarDenegationActive: false,
      isLoading: true,
      isDialogVisible: false,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
      },
      allItems: false,
      model: {},
      perfiles: [],
      typeAfiliados: [],
      typeAfiliado: null,
      ejecutivo_id: null,
      ejecutivos: [],
      loading: false,
      products: [],
      nom_tipo_products_id: [],
      is_proveedor: false,
      codeUnique: null,
    }
  },
  computed: {
    ...mapState({
      loadingBtn: state => state.app.loadingBtn,
    }),
    btnTitle() {
      return sessionStorage.getItem('markups-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
  },
  created() {
    this.getCodeUnique()
    this.getProducts()
    this.getPerfiles()
    this.getTypeAfiliado()
    this.getEjecutivos()
    this.getItem()
  },
  methods: {
    ...mapMutations(['updateLoadingBtn']),
    getCodeUnique() {
      this.axios
        .post(
          'affiliate/code_unique',
          { id: sessionStorage.getItem('user-register-id') },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.codeUnique = res.data.data.code
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.products = res.data.data
        })
    },
    getItem() {
      if (sessionStorage.getItem('user-register-id') !== null) {
        const id = sessionStorage.getItem('user-register-id')
        this.axios
          .get(`affiliate/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.perfiles.length > 0) {
              this.model.perfiles.forEach(element => {
                if (element.slug === 'agencia') {
                  this.is_proveedor = true
                }
              })
            }
          })
      }
    },
    getPerfiles() {
      this.axios.get('perfil-afiliados').then(res => {
        this.perfiles = res.data.data
      })
    },
    getTypeAfiliado() {
      this.axios
        .get(`tipo_afiliado?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            const result = []
            res.data.data.forEach(element => {
              if (element.slug !== 'propios') {
                result.push(element)
              }
            })
            this.typeAfiliados = result
          }
        })
    },
    getEjecutivos() {
      this.axios
        .post('user/list-by-rol', { per_page: 1000 }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) { } else {
            this.ejecutivos = res.data.data
          }
        })
    },
    aprobar() {
      let procesar = true
      if (this.is_proveedor) {
        if (!this.typeAfiliado || !this.ejecutivo_id || this.nom_tipo_products_id.length === 0) {
          procesar = false
        }
      } else if (this.nom_tipo_products_id.length === 0) {
        procesar = false
      }
      if (procesar) {
        this.isDialogVisible = false
        this.loading = true
        const json = {
          aprobada: true,
          typeAfiliado: this.typeAfiliado,
          ejecutivo_id: this.ejecutivo_id,
          nom_tipo_products_id: this.nom_tipo_products_id,
          codeUnique: this.codeUnique,
        }
        this.axios
          .put(`affiliate/${sessionStorage.getItem('user-register-id')}`, json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            if (response.data.success === false) {
              if (response.data.data.status === 401) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else if (response.data.data.status === 402) {
                this.$toast.error(this.$t('msg.userExist', { email: response.data.data.email }))
              }
            } else {
              sessionStorage.removeItem('user-register-id')
              this.$toast.info(this.$t('msg.sendEmail', { email: this.model.email_contact }))

              this.$router.push({ name: 'user-register-list' })
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    denegar() {
      this.isSidebarDenegationActive = true
    },
    confirmDenegar(denegation) {
      this.updateLoadingBtn(true)

      const json = {
        aprobada: false,
        denegation,
      }
      this.axios
        .put(
          `affiliate/${sessionStorage.getItem('user-register-id')}`,
          json,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            sessionStorage.removeItem('user-register-id')
            this.$toast.info(this.$t('msg.sendEmail', { email: this.model.email_contact }))
            this.$router.push({ name: 'user-register-list' })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.updateLoadingBtn(false)))
    },

    setAllItems() {
      this.nom_tipo_products_id = []
      if (this.allItems) {
        this.products.forEach(element => {
          this.nom_tipo_products_id.push(element.id)
        })
      }
    },
    verifyAllItems() {
      this.allItems = false
      if (this.nom_tipo_products_id.length === this.products.length) {
        this.allItems = true
      }
    },
  },
}
</script>
