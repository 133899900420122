<template>
  <v-navigation-drawer
    :value="isSidebarDenegationActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-denegation-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('lbl.denegarAfil') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-denegation-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="stepForm">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <tiptap-vuetify
                v-model="denegation"
                :extensions="extensions"
                :placeholder="`${$t('lbl.denegarMotive')}...`"
              />
              <!--<v-textarea
                v-model="denegation"
                :label="`${$t('lbl.denegarMotive')}...`"
                outlined
                rows="5"
                row-height="5"
              ></v-textarea>-->
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              color="error"
              block
              @click="$emit('update:is-sidebar-denegation-active', false)"
            >
              {{ $t('btn.cancele') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              color="primary"
              outlined
              block
              :disabled="!denegation"
              :loading="loadingBtn"
              @click="$emit('confirmDenegar', denegation)"
            >
              {{ $t('btn.send') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
import {
  TiptapVuetify,
  Bold,
  Italic,

  // BulletList,
  // OrderedList,
  // ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'

export default {
  components: {
    TiptapVuetify,
  },
  model: {
    prop: 'isSidebarDenegationActive',
    event: 'update:is-sidebar-denegation-active',
  },
  props: {
    isSidebarDenegationActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      stepForm: null,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      logo: null,
      denegation: null,
      extensions: [
        // BulletList,
        // OrderedList,
        // ListItem,
        Italic,
        Bold,
        HardBreak,
      ],
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
      proveedorApis: state => state.app.proveedorApis,
      clientsApis: state => state.app.clientsApis,
    }),
  },

  methods: {
    ...mapMutations(['updateProveedorApi', 'addProveedorApi', 'updateClientApi', 'addClientApi']),
  },
}
</script>
